<template>
  <div>
    <title-header :title="title" :buttons="headerButtons"></title-header>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field v-model="search" class="ml-5" append-icon="mdi-magnify" label="Buscar" single-line clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="roles" :search="search" class="elevation-1 pl-10 pr-10">
      <template v-slot:item.buttons="{ item }">
        <v-row justify="end">
          <v-btn small rounded outlined class="mr-2" color="primary"
            @click="routeToEditRole(item.id)"><v-icon style="font-size: 14px">mdi-pencil</v-icon></v-btn>
          <v-btn small rounded outlined class="mx-2" color="red" @click="showRemoveRoleDialog(item)"><v-icon style="font-size: 14px">mdi-delete</v-icon></v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">¿Eliminar {{ selectedItem.name }}?</v-card-title>
        <v-card-text>¿Seguro que deseas eliminar este rol?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="deleteDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="onRemoveRole(selectedItem)">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import TitleHeader from '@/components/app/title-header/title-header'
import { mapGetters, mapActions } from 'vuex'
import { ROLES_GET_ROLES, ROLES_DELETE_ROLE } from '../../../store/actions.type'

export default {
  name: 'rols-list',
  props: {},
  data() {
    return {
      deleteDialog: false,
      title: 'Roles',
      selectedItem: {},
      headerButtons: [
        {
          text: 'Nuevo rol',
          urlSlug: 'roles/create'
        }
      ],
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripcion', value: 'description' },
        { text: '', value: 'buttons', width: 265 }
      ],
      rols: []
    }
  },
  computed: {
    ...mapGetters({
      roles: 'getRoles'
    })
  },
  methods: {
    ...mapActions({
      getRoles: ROLES_GET_ROLES,
      deleteRole: ROLES_DELETE_ROLE
    }),
    showRemoveRoleDialog(item) {
      this.selectedItem = item
      this.deleteDialog = true
    },
    onRemoveRole(selectedItem) {
      this.deleteRole(selectedItem.id)
        .then(response => {
          this.getRoles()
          this.deleteDialog = false
          this.showSnackbar = true
          this.snackBarText = 'El rol fue eliminado'
        })
        .catch(e => {
          this.showSnackbar = true
          this.snackBarText = e.message
        })
    },
    routeToEditRole(roleId) {
      this.$router.push('roles/' + roleId)
    }
  },
  components: {
    TitleHeader
  },
  mounted() {
    this.getRoles()
  }
}
</script>
